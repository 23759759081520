
import { defineComponent } from "vue";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "image-usage",
  components: {
    CodeHighlighter
  }
});
